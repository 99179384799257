<template>
  <div>
    <div class="header-text pb-3" data-cy="header-admin-student">
      Student Records
    </div>
    <div class="subhead-text pb-5">
      Manage student records for the Mentor Externship Program.
    </div>
    <div class="box-style mb-5">
      <div class="secondhead-text">Filters</div>
      <div class="grid grid-cols-4 gap-5 mb-3">
        <div>
          <div class="mb-2">Status</div>
          <ul class="grid grid-cols-2 gap-1">
            <li class="relative inline-block">
              <input
                class="sr-only peer"
                type="radio"
                name="at_level"
                id="status-all"
                value=""
                v-model="studentStatus"
              />
              <label
                class="cursor-pointer block border border-dark-purple rounded-full text-xl text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                for="status-all"
                >All</label
              >
            </li>
            <li
              class="relative inline-block"
              v-for="(status, key) in lookup_student_status_options"
              :key="key"
            >
              <input
                class="sr-only peer"
                type="radio"
                name="at_level"
                :id="`status-active_${key}`"
                :value="status"
                v-model="studentStatus"
              />
              <label
                class="cursor-pointer block border border-dark-purple rounded-full text-xl text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                :for="`status-active_${key}`"
                >{{
                  status
                    .split("_")
                    .map((w) => `${w.charAt(0)}${w.substring(1).toLowerCase()}`)
                    .join(" ")
                }}</label
              >
            </li>
          </ul>
        </div>
        <div>
          <div class="mb-2">Grade Level</div>
          <div>
            <ul class="grid grid-cols-3 gap-1">
              <li class="relative inline-block">
                <button
                  :class="{
                    'block border border-dark-purple rounded-full text-xl text-dark-purple py-1 text-centers w-full h-8 peer-checked:bg-dark-purple peer-checked:text-white': true,
                    'bg-dark-purple text-white': academicYear.length < 1,
                  }"
                  :id="`level_none`"
                  @click="academicYear = []"
                >
                  All
                </button>
              </li>
              <li
                class="relative inline-block"
                v-for="(acad_lvl, key) in lookup_acad_levels"
                :key="key"
              >
                <input
                  class="sr-only peer"
                  type="checkbox"
                  name="at_level"
                  :id="`level_${acad_lvl.value}`"
                  :value="acad_lvl.level"
                  v-model="academicYear"
                />
                <label
                  class="block border border-dark-purple rounded-full text-xl text-dark-purple pt-1 text-center w-full h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                  :for="`level_${acad_lvl.value}`"
                  >{{ acad_lvl.level }}</label
                >
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div class="mb-2">Transportation</div>
          <ul class="grid grid-cols-2 gap-1">
            <li class="relative inline-block">
              <input
                class="sr-only peer"
                type="radio"
                name="transportation"
                id="transportaion_yes"
                :value="true"
                v-model="transportation"
              />
              <label
                class="block border border-dark-purple rounded-full text-xl text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                for="transportaion_yes"
                >Yes</label
              >
            </li>
            <li class="relative inline-block">
              <input
                class="sr-only peer"
                type="radio"
                name="transportation"
                id="transportaion_no"
                :value="false"
                v-model="transportation"
              />
              <label
                class="block border border-dark-purple rounded-full text-xl text-dark-purple pt-1 text-center h-8 peer-checked:bg-dark-purple peer-checked:text-white"
                for="transportaion_no"
                >No</label
              >
            </li>
          </ul>
        </div>
        <div>
          <div class="mb-2">Interest Areas</div>
          <select
            id="dropdown-practice-areas"
            class="text-sm p-1 pl-2 border-steel-gray rounded h-8 w-full"
            v-model="practiceAreaId"
          >
            <option value=""></option>
            <option
              v-for="practiceArea in lookup_practice_areas"
              :key="practiceArea.practice_area_id"
              :value="practiceArea.practice_area_id"
            >
              {{ practiceArea.description }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <label class="mb-2" for="student-textbox-filter">
          Student Name / Email
        </label>
        <input
          id="student-textbox-filter"
          type="text"
          v-model="filterText"
          class="border-steel-gray rounded h-8 w-full disabled:bg-light-gray"
          :disabled="!loaded"
        />
      </div>
      <div class="profile_box">
        <div class="flex justify-end items-center">
          <button
            @click="reset"
            class="py-2 px-4 bg-white border-purple border rounded-full text-dark-purple hover:text-white hover:bg-purple"
          >
            Reset Filters
          </button>
        </div>
      </div>
    </div>
    <div class="box-style overflow-auto">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div v-if="students">
          <dynamic-table
            :records="this.students"
            :fields="tableConfig"
            :pagination-page="paginationPage"
            :pagination-total-items="paginationTotalItems"
            @pageChanged="nextPage($event)"
            no-data-msg="No students to display for the current filter"
            hover
            striped
            pagination
          >
            <template #cell(view)="{ item }">
              <router-link :to="'./students/detail/' + item.student_id">
                <font-awesome-icon icon="plus" class="text-dark-purple" />
              </router-link>
            </template>
            <template #cell(student-masq)="{ item }">
              <button
                v-if="isAdm()"
                aria-label="masquerade-as-user-button"
                :id="item.student_id"
                class="w-6 h-6 text-dark-purple hover:bg-dark-purple hover:text-white border rounded"
                @click="masqueradeAs(item.student_id)"
              >
                <font-awesome-icon icon="theater-masks" />
              </button>
            </template>
            <template #cell(student-name)="{ item }">
              {{ item.firstname }} {{ item.lastname }}
            </template>
            <template #cell(student-year)="{ item }">
              {{
                item.profiles.length
                  ? item.profiles.find((s) => s.current).acad_level.level
                  : "None"
              }}
            </template>
            <template #cell(student-activity)="{ item }">
              {{ this.FormatDate(item.last_active_date) }}
            </template>
            <template #cell(phone)="{ item }">
              {{ getPhone(item) }}
            </template>
            <template #cell(email)="{ item }">
              <a class="anchor" :href="`mailto:${getEmail(item)}`">
                {{ getEmail(item) }}
              </a>
            </template>
          </dynamic-table>
        </div>
        <div v-else>
          <label>Please select desired filters and click apply</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { get } from "@/composables/httpUtil";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { FormatDate } from "@/composables/date_utils";
import { mapActions, mapGetters } from "vuex";
import { isAdm, isSra, isFac, isSaj } from "@/composables/tokenUtils";

export default {
  name: "StudentRecords",
  components: { DynamicTable, LoadingSpinner },
  data() {
    return {
      students: [],
      filterText: "",
      isLoading: false,
      loaded: false,
      studentStatus: "ACTIVE",
      transportation: undefined,
      academicYear: [],
      practiceAreaId: "",
      paginationTotalItems: 0,
      paginationTotalPages: 0,
      itemsPerPage: 25,
      paginationPage: 1,

      tableConfig: [
        { name: "view", display: "" },
        {
          display: "Name",
          name: "student-name",
          sortable: true,
          class: "",
        },
        {
          display: "Year",
          name: "student-year",
          sortable: true,
          class: "",
        },
        {
          display: "Phone",
          name: "phone",
          sortable: true,
          class: "",
        },
        {
          display: "Email",
          name: "email",
          sortable: true,
          class: "",
        },
        {
          display: "Last Activity",
          name: "student-activity",
          sortable: true,
          class: "",
        },
        {
          display: this.isAdm() ? "Masquerade" : "",
          name: this.isAdm() ? "student-masq" : "",
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  created() {
    Promise.all([this.fetchLookupResource, this.fetchListOfStudents()]).then(() => {this.loaded=true});
  },
  watch: {
    filterText: {
      handler() {
        this.fetchListOfStudents();
      },
      immediate: false,
    },
    studentStatus: {
      handler() {
        this.fetchListOfStudents();
      },
      immediate: false,
    },
    transportation: {
      handler() {
        this.fetchListOfStudents();
      },
      immediate: false,
    },
    academicYear: {
      handler() {
        this.fetchListOfStudents();
      },
      immediate: false,
    },
    practiceAreaId: {
      handler() {
        this.fetchListOfStudents();
      },
      immediate: false,
    },
  },
  methods: {
    isAdm,
    isSra,
    isFac,
    isSaj,
    fetchListOfStudents() {
      let url = `admin/students?page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      url += this.studentStatus !== "" ? `&status=${this.studentStatus}` : "";
      url +=
        this.academicYear.length > 0
          ? `&academic_level=${this.academicYear.join("&academic_level=")}`
          : "";
      url +=
        this.transportation !== undefined
          ? `&transportation=${this.transportation}`
          : "";
      url +=
        this.practiceAreaId !== ""
          ? `&interest_area=${this.practiceAreaId}`
          : "";
      url += this.filterText !== "" ? `&name=${this.filterText}` : "";

      if (
        this.academicYear.length > 0 ||
        this.transportation !== undefined ||
        this.practiceAreaId !== "" ||
        this.studentStatus === "ACTIVE"
      ) {
        url += `&term_id=${this.currentTermId}`;
      }

      this.isLoading = true;
      return get(url)
        .then((results) => {
          this.students = results.data;

          this.paginationTotalItems = results.count;
          this.paginationTotalPages = results.total_pages;
        })
        .catch((error) => {
          //TODO: do something with this error and display it to the user
          console.log("error in catch statement", error);
        })
        .finally(() => (this.isLoading = false));
    },
    reset() {
      this.students = [];
      this.transportation = undefined;
      this.studentStatus = "";
      this.practiceAreaId = "";
      this.academicYear = [];
      this.isLoading = false;
      this.filterText = "";
    },
    masqueradeAs: function (id) {
      this.$store.dispatch("SetIsMasquerading", true);
      this.$store.dispatch("FetchMasqToken", { id: id });
    },
    nextPage(paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.fetchListOfStudents();
    },
    FormatDate,
    getEmail(item) {
      let email = "No Email";
      if (
        item.email.length &&
        item.email.find((e) => e.email_type === "ST_THOMAS")
      ) {
        email = item.email.find(
          (e) => e.email_type === "ST_THOMAS"
        ).email_address;
      }
      return email;
    },
    getPhone(item) {
      let phone = "No Phone";
      if (item.phone.length && item.phone.find((p) => p.is_public === true)) {
        phone = item.phone.find((p) => p.is_public === true).phone_number;
      }
      return phone;
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters([
      "lookup_student_status_options",
      "lookup_acad_levels",
      "lookup_practice_areas",
      "lookup_current_term",
    ]),
    currentTermId: function () {
      return this.lookup_current_term.term_id;
    },
  },
};
</script>

<style scoped></style>
